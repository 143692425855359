<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1"
    v-if="preference"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="about-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_preferences-set-preference-help-online',
          query: { slug: helpSlug }
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_preference-category' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ preference.name }}
        </div>
        <template
          v-slot:right
          v-if="!preferenceAutomaticSave.includes(preference.preference)"
        >
          <button @click="updatePreference()">
            <icon icon="#cx-hea1-edit-done" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="setAutomatically"
                  type="checkbox"
                  v-model="isAutomatic"
                />
                <label for="setAutomatically"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{
                displayLabelName(
                  "preferences",
                  "set-preferences",
                  "set-automatically"
                )
              }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <div class="preference-list">
      <component
        v-bind:is="preferenceComponentsMap[preference.preference]"
        :preferenceId="preference.id"
      ></component>
    </div>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";
import { apiEndpoints } from "@/services/constants";
import {
  preferenceAutomaticSave,
  preferenceComponentsMap,
  preferenceSetIdsHelpOnline
} from "@/services/preference-options";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SetPreference",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    StartUpPage: defineAsyncComponent(() =>
      import("@/components/preferences/landing-page/StartUpPage")
    ),
    DefaultLocation: defineAsyncComponent(() =>
      import("@/components/preferences/location/DefaultLocation")
    ),
    FirstDayOfWeek: defineAsyncComponent(() =>
      import("@/components/preferences/calendar/FirstDayOfWeek")
    ),
    WeekendDays: defineAsyncComponent(() =>
      import("@/components/preferences/calendar/WeekendDays")
    ),
    DisplayWeekend: defineAsyncComponent(() =>
      import("@/components/preferences/calendar/DisplayWeekend")
    ),
    DefaultSlot: defineAsyncComponent(() =>
      import("@/components/preferences/slots/DefaultSlot")
    ),
    DateFormat: defineAsyncComponent(() =>
      import("@/components/preferences/date-time/DateFormat")
    ),
    TimeFormat: defineAsyncComponent(() =>
      import("@/components/preferences/date-time/TimeFormat")
    ),
    DateTimeMode: defineAsyncComponent(() =>
      import("@/components/preferences/date-time/DateTimeMode")
    ),
    DefaultMeetingTitle: defineAsyncComponent(() =>
      import("@/components/preferences/booking/DefaultMeetingTitle")
    )
  },
  data() {
    return {
      preferenceAutomaticSave: preferenceAutomaticSave,
      preferenceComponentsMap: preferenceComponentsMap,
      preferenceSetIdsHelpOnline: preferenceSetIdsHelpOnline,
      preference: null,
      isAutomatic: true,
      helpSlug: null
    };
  },
  computed: {
    ...mapState("preferences", ["preferences", "preferenceUpdateData"])
  },
  methods: {
    ...mapActions("preferences", ["setPreferenceUpdateData", "setPreferences"]),
    getPreference() {
      if (this.preferences && this.preferences.length) {
        this.preference = this.preferences.find(
          preference => preference.id == this.$route.params.preferenceId
        );
        this.isAutomatic = this.preference.is_automatic === 1 ? true : false;
        this.setData();
        this.helpSlug = this.preferenceSetIdsHelpOnline[
          this.preference.preference
        ];
        this.getHelpOnline(this.helpSlug);
      }
    },
    setData(updatePreference) {
      const preferenceUpdateData = {
        is_automatic: this.isAutomatic,
        values: this.isAutomatic
          ? this.preference.preference_values
          : this.preference.preference_user_values.length
          ? this.preference.preference_user_values
          : this.preference.preference_values
      };
      if (!this.isAutomatic && !this.preference.preference_user_values.length) {
        preferenceUpdateData.values.forEach(pref => {
          delete pref.id;
        });
      }
      this.setPreferenceUpdateData(preferenceUpdateData);
      if (updatePreference) {
        this.updatePreference();
      }
    },
    updatePreference() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.userPreferences}/${this.preference.id}`,
          this.preferenceUpdateData
        )
        .then(response => {
          const preferencesUpdateData = [...this.preferences];
          const objIndex = preferencesUpdateData.findIndex(
            obj => obj.id == response.data.data.id
          );
          preferencesUpdateData[objIndex] = response.data.data;
          this.setPreferences(preferencesUpdateData);
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.$router.push({ name: "r_preference-category" });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    }
  },
  created() {
    this.getPreference();
  },
  watch: {
    "$route.params.preferenceId"(value) {
      if (value) {
        this.getPreference();
      }
    },
    preferences() {
      this.getPreference();
    },
    isAutomatic() {
      this.setData(this.isAutomatic);
    }
  }
};
</script>
